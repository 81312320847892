// 员工新增，编辑弹窗
// sle
<template>
  <div id="EmployeeEditView">
    <a-modal
      v-model:visible="visible"
      :maskClosable="false"
      :title="title"
      @cancel="closeEmployeeView"
      :width="more ? 1200 : 520"
    >
      <template v-slot:footer>
        <div class="footer flex-row justify-between">
          <span class="left">
            <a-button
              @click="unfoldMore"
              type="primary"
              v-if="!more"
              :loading="spinning"
            >
              展开更多
            </a-button>
            <a-button @click="packMore" v-else :loading="spinning"
              >收起</a-button
            >
          </span>
          <span class="right">
            <a-button @click="closeEmployeeView">取消</a-button>
            <a-button
              @click="submitEditEmployee"
              type="primary"
              :loading="spinning"
              >保存</a-button
            >
          </span>
        </div>
      </template>
      <a-spin :spinning="spinning">
        <a-form
          :model="obj"
          ref="formRef"
          :rules="rules"
          autocomplete="off"
          :labelCol="{ span: 5 }"
          :wrapperCol="{ span: 16 }"
          labelAlign="left"
        >
          <div v-if="!more">
            <a-form-item label="工号" required name="employeeCode">
              <a-input
                class="input"
                v-model:value="obj.employeeCode"
                required
                :maxlength="20"
              />
            </a-form-item>
            <a-form-item label="姓名" required name="employeeName">
              <a-input
                class="input"
                v-model:value="obj.employeeName"
                required
                :maxlength="15"
              />
            </a-form-item>
            <a-form-item label="手机" required name="phoneNumber">
              <a-input
                class="input"
                v-model:value="obj.phoneNumber"
                required
                :maxlength="11"
                :disabled="obj.isBind"
              />
              <label v-if="obj.isBind" class="isBind">已绑定用户信息，无法修改手机号</label>
            </a-form-item>
            <a-form-item label="部门">
              <a-select
                v-model:value="obj.departmentId"
                placeholder="请选择部门"
                :allowClear="true"
              >
                <a-select-option
                  v-for="r in departmentList"
                  :key="r.linkKey"
                  :value="r.linkKey"
                  >{{ r.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item label="岗位">
              <a-select
                mode="multiple"
                v-model:value="postSelectRows"
                placeholder="请选择岗位"
              >
                <a-select-option
                  v-for="r in postList"
                  :key="r.linkKey"
                  :value="r.linkKey"
                  >{{ r.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </div>
          <div v-if="more">
            <a-collapse v-model:activeKey="activeKey">
              <a-collapse-panel key="1" header="基础信息" disabled>
                <a-row>
                  <a-col :span="12" class="clos">
                    <a-form-item label="工号" required name="employeeCode">
                      <a-input
                        class="input"
                        v-model:value="obj.employeeCode"
                        required
                        :maxlength="20"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" class="clos">
                    <a-form-item label="姓名" required name="employeeName">
                      <a-input
                        class="input"
                        v-model:value="obj.employeeName"
                        required
                        :maxlength="15"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12" class="clos">
                    <a-form-item label="手机" required name="phoneNumber">
                      <a-input
                        class="input"
                        v-model:value="obj.phoneNumber"
                        required
                        :maxlength="11"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" class="clos">
                    <a-form-item label="邮箱">
                      <a-input
                        class="input"
                        v-model:value="obj.email"
                        :maxlength="40"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12" class="clos">
                    <a-form-item label="部门">
                      <a-select
                        class="select"
                        v-model:value="obj.departmentId"
                        placeholder="请选择部门"
                        :allowClear="true"
                      >
                        <a-select-option
                          v-for="r in departmentList"
                          :key="r.linkKey"
                          :value="r.linkKey"
                        >
                          {{ r.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" class="clos">
                    <a-form-item label="岗位">
                      <a-select
                        mode="multiple"
                        v-model:value="postSelectRows"
                        placeholder="请选择岗位"
                      >
                        <a-select-option
                          v-for="r in postList"
                          :key="r.linkKey"
                          :value="r.linkKey"
                          >{{ r.name }}</a-select-option
                        >
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12" class="clos">
                    <a-form-item label="员工级别">
                      <a-select
                        class="select"
                        v-model:value="obj.employeeLevelId"
                        placeholder="请选择员工级别"
                        :allowClear="true"
                      >
                        <a-select-option
                          v-for="r in levelList"
                          :key="r.linkKey"
                          :value="r.linkKey"
                        >
                          {{ r.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" class="clos">
                    <a-form-item label="就职状态">
                      <a-select
                        class="select"
                        v-model:value="obj.employeeState"
                        placeholder="请选择当前就职状态"
                        :allowClear="true"
                      >
                        <a-select-option
                          v-for="r in stateList"
                          :key="r.linkKey"
                          :value="r.linkKey"
                        >
                          {{ r.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12" class="clos">
                    <a-form-item label="教育程度">
                      <a-select
                        class="select"
                        v-model:value="obj.educationDegreeId"
                        placeholder="请选择教育程度"
                        :allowClear="true"
                      >
                        <a-select-option
                          v-for="r in educationDegreeList"
                          :key="r.linkKey"
                          :value="r.linkKey"
                        >
                          {{ r.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-collapse-panel>
              <a-collapse-panel key="2" header="银行账户信息">
                <a-row>
                  <a-col :span="12" class="clos">
                    <a-form-item label="开户账号">
                      <a-input
                        class="input"
                        v-model:value="obj.bankAccount"
                        :maxlength="30"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" class="clos">
                    <a-form-item label="开户行">
                      <a-input
                        class="input"
                        v-model:value="obj.openBank"
                        :maxlength="30"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12" class="clos">
                    <a-form-item label="支行">
                      <a-input
                        class="input"
                        v-model:value="obj.branch"
                        :maxlength="30"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-collapse-panel>
              <a-collapse-panel key="3" header="身份信息">
                <a-row>
                  <a-col :span="12" class="clos">
                    <a-form-item label="身份证">
                      <a-input
                        class="input"
                        v-model:value="obj.idCardNumber"
                        :maxlength="18"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" class="clos">
                    <a-form-item label="性别">
                      <a-select
                        class="select"
                        v-model:value="obj.gender"
                        placeholder="请选择性别"
                        :allowClear="true"
                      >
                        <a-select-option
                          v-for="r in genderList"
                          :key="r.linkKey"
                          :value="r.linkKey"
                        >
                          {{ r.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12" class="clos">
                    <a-form-item label="出生日期">
                      <a-date-picker
                        :locale="locale"
                        class="datepick"
                        placeholder="请选择日期"
                        v-model:value="obj.birthday"
                      >
                      </a-date-picker>
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" class="clos">
                    <a-form-item label="民族">
                      <a-input
                        class="input"
                        v-model:value="obj.national"
                        :maxlength="10"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12" class="clos">
                    <a-form-item label="籍贯">
                      <a-input
                        class="input"
                        v-model:value="obj.nativePlace"
                        :maxlength="15"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" class="clos">
                    <a-form-item label="婚姻状况">
                      <a-select
                        class="select"
                        v-model:value="obj.maritalStatus"
                        placeholder="请选择婚姻状况"
                        :allowClear="true"
                      >
                        <a-select-option
                          v-for="r in maritalList"
                          :key="r.linkKey"
                          :value="r.linkKey"
                        >
                          {{ r.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12" class="clos">
                    <a-form-item label="现住址">
                      <a-textarea
                        class="input"
                        v-model:value="obj.currentAddress"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-collapse-panel>
              <a-collapse-panel key="4" header="联系人信息">
                <a-row>
                  <a-col :span="12" class="clos">
                    <a-form-item label="紧急联系人">
                      <a-input
                        class="input"
                        v-model:value="obj.emergencyContact"
                        :maxlength="15"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" class="clos">
                    <a-form-item label="紧急联系人电话">
                      <a-input
                        class="input"
                        v-model:value="obj.emergencyContactNumber"
                        :maxlength="22"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12" class="clos">
                    <a-form-item label="紧急联系人地址">
                      <a-input
                        class="input"
                        v-model:value="obj.emergencyContactAddress"
                        :maxlength="100"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" class="clos">
                    <a-form-item label="紧急联系人关系">
                      <a-input
                        class="input"
                        v-model:value="obj.emergencyContactRelationship"
                        :maxlength="15"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-collapse-panel>
              <a-collapse-panel key="5" header="实习转试用">
                <a-row>
                  <a-col :span="12" class="clos">
                    <a-form-item label="实习日期">
                      <a-date-picker
                        :locale="locale"
                        placeholder="请选择日期"
                        class="datepick"
                        v-model:value="obj.internshipDate"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" class="clos">
                    <a-form-item label="实习期（月）">
                      <a-InputNumber
                        min="0"
                        class="input"
                        v-model:value="obj.internship"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12" class="clos">
                    <a-form-item label="实习薪资（元）">
                      <a-InputNumber
                        min="0"
                        class="input"
                        v-model:value="obj.internshipSalary"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" class="clos">
                    <a-form-item label="转试用日期">
                      <a-date-picker
                        :locale="locale"
                        placeholder="请选择日期"
                        class="datepick"
                        v-model:value="obj.transferDate"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-collapse-panel>
              <a-collapse-panel key="6" header="入职转正">
                <a-row>
                  <a-col :span="12" class="clos">
                    <a-form-item label="入职日期">
                      <a-date-picker
                        :locale="locale"
                        placeholder="请选择日期"
                        class="datepick"
                        v-model:value="obj.entryDate"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" class="clos">
                    <a-form-item label="试用期（月）">
                      <a-InputNumber
                        min="0"
                        class="input"
                        v-model:value="obj.probationPeriod"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12" class="clos">
                    <a-form-item label="转正日期">
                      <a-date-picker
                        placeholder="请选择日期"
                        :locale="locale"
                        class="datepick"
                        v-model:value="obj.obtainmentDate"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" class="clos">
                    <a-form-item label="转正薪资">
                      <a-InputNumber
                        min="0"
                        class="input"
                        v-model:value="obj.obtainmentSalary"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12" class="clos">
                    <a-form-item label="试用薪资">
                      <a-InputNumber
                        min="0"
                        class="input"
                        v-model:value="obj.probationSalary"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" class="clos">
                    <a-form-item label="当前薪资">
                      <a-InputNumber
                        min="0"
                        class="input"
                        v-model:value="obj.currentSalary"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-collapse-panel>
              <a-collapse-panel key="7" header="合同信息">
                <a-row>
                  <a-col :span="12" class="clos">
                    <a-form-item label="合同期">
                      <a-input
                        class="input"
                        v-model:value="obj.contractTerm"
                        :maxlength="10"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="12" class="clos">
                    <a-form-item label="合同开始日期">
                      <a-date-picker
                        :locale="locale"
                        placeholder="请选择日期"
                        class="datepick"
                        v-model:value="obj.contractStartDate"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" class="clos">
                    <a-form-item label="合同结束日期">
                      <a-date-picker
                        :locale="locale"
                        placeholder="请选择日期"
                        class="datepick"
                        v-model:value="obj.contractEndDate"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-collapse-panel>
              <a-collapse-panel key="8" header="离职信息">
                <a-row>
                  <a-col :span="12" class="clos">
                    <a-form-item label="离职日期">
                      <a-date-picker
                        :locale="locale"
                        placeholder="请选择日期"
                        class="datepick"
                        v-model:value="obj.departureDate"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :span="12" class="clos">
                    <a-form-item label="离职原因">
                      <a-textarea
                        class="input"
                        v-model:value="obj.departureReason"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-collapse-panel>
            </a-collapse>
          </div>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
// import moment from 'moment'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
import { getPostSelectList } from '@/api/SelectList/AddressBook'
import 'moment/locale/zh-cn'

export default defineComponent({
  emits: ['employeeEditBeforeReload'],
  props: {
    departmentList: {
      type: Array,
    },
  },
  data () {
    return {
      locale,
      activeKey: ['1'], // 打开的折叠区
      more: false, // 是否编辑更多
      add: false, // 是否新增操作
      postList: [], // 岗位下拉list
      levelList: [], // 等级下拉list
      educationDegreeList: [], // 教育程度下拉list
      stateList: [], // 状态下拉list
      genderList: [], // 性别下拉list
      maritalList: [], // 婚姻状况下拉list
      postSelectRows: [], // 选中岗位行
      visible: false, // 页面是否显示
      spinning: false, // 是否加载
      title: '', // 标题
      obj: {
        id: null, // 成员Id
        employeeCode: '', // 成员编码
        employeeName: '', // 成员姓名
        phoneNumber: '', // 成员联系方式
        departmentId: null, // 部门Id
      }, // 编辑的实体类
      rules: {
        employeeCode: [
          {
            required: true,
            message: '请输入工号',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 20,
            message: '长度请控制在20以内',
            trigger: 'blur',
          },
        ],
        employeeName: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 15,
            message: '长度请控制在15以内',
            trigger: 'blur',
          },
        ],
        phoneNumber: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur',
          },
          {
            min: 11,
            max: 11,
            message: '请输入正确的11位手机号',
            trigger: 'blur',
          },
        ],
      }, // 验证规则
    }
  },
  methods: {
    // #region 获取各列表，数据
    // 获取成员信息
    getEmployeeData (id) {
      this.spinning = true
      api
        .get('/api/app/employee/employee', {
          params: {
            Id: id,
          },
        })
        .then(({ data }) => {
          this.spinning = false
          this.obj = data
          const list = []
          data.employeeContrastList.forEach(element => {
            list.push(element.employeePostId)
          })
          this.postSelectRows = list
          this.visible = true
          this.add = false
          this.title = '编辑信息'
          this.getPostDutySelectList()
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取成员信息失败')
          console.log(err)
        })
    },
    // 获取岗位列表
    getPostDutySelectList () {
      getPostSelectList()
        .then(res => {
          this.postList = res.data
        })
        .catch(err => {
          // this.$message.error('获取岗位数据失败')
          console.log(err)
        })
    },
    // 获取等级列表
    getlLevelSelectList () {
      api
        .get('/api/app/employee-level/select-list')
        .then(({ data }) => {
          this.levelList = data
        })
        .catch(err => {
          // this.$message.error('获取等级数据失败')
          console.log(err)
        })
    },
    // 获取教育程序列表
    getEducationDegreeSelectList () {
      api
        .get('/api/app/education-degree/select-list')
        .then(({ data }) => {
          this.educationDegreeList = data
        })
        .catch(err => {
          // this.$message.error('获取教育程序数据失败')
          console.log(err)
        })
    },
    // 获取状态列表
    getStateSelectList () {
      api
        .get('/api/app/employee/employee-state-select-list')
        .then(({ data }) => {
          this.stateList = data
        })
        .catch(err => {
          // this.$message.error('获取状态数据失败')
          console.log(err)
        })
    },
    // 获取性别列表
    getGenderSelectList () {
      api
        .get('/api/app/method/gender-select-list')
        .then(({ data }) => {
          this.genderList = data
        })
        .catch(err => {
          // this.$message.error('获取性别数据失败')
          console.log(err)
        })
    },
    // 获取婚姻状况列表
    getMaritalSelectList () {
      api
        .get('/api/app/employee/marital-select-list')
        .then(({ data }) => {
          this.maritalList = data
        })
        .catch(err => {
          // this.$message.error('获取婚姻状况数据失败')
          console.log(err)
        })
    },
    // #endregion

    // 展开更多
    unfoldMore () {
      this.more = true
      this.getlLevelSelectList()
      this.getEducationDegreeSelectList()
      this.getStateSelectList()
      this.getGenderSelectList()
      this.getMaritalSelectList()
    },
    // 取消编辑更多
    packMore () {
      this.more = false
      this.activeKey = ['1']
    },

    // 添加成员按钮，呼出添加页面
    addEmployee (departmentId) {
      this.visible = true
      this.add = true
      this.title = '添加成员'
      this.obj.departmentId = departmentId === 'all' ? null : departmentId
      this.getPostDutySelectList()
    },
    // 编辑按钮，呼出编辑页面
    editEmployee (id) {
      this.getEmployeeData(id)
    },
    // 取消，关闭成员信息添加页面
    closeEmployeeView () {
      this.activeKey = ['1']
      this.more = false
      this.visible = false
      this.add = false
      this.title = ''
      this.$refs.formRef.resetFields()
      this.obj = {}
      this.postSelectRows = []
    },
    // 确认新增成员信息操作
    submitEditEmployee () {
      this.$refs.formRef
        .validate()
        .then(() => {
          const employeePostList = []
          this.postSelectRows.forEach(element => {
            const obj = {
              employeePostId: element,
            }
            employeePostList.push(obj)
          })
          this.spinning = true
          const obj = this.obj
          obj.employeePostList = employeePostList
          api
            .post('/api/app/employee/or-update-employee', obj)
            .then(({ data }) => {
              this.$message.success('操作成功')
              this.$emit('employeeEditBeforeReload')
              this.closeEmployeeView()
              this.spinning = false
            })
            .catch(err => {
              this.spinning = false
              // this.$message.error('操作失败')
              console.log(err)
            })
        })
        .catch(error => {
          console.log('error', error)
        })
    },
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";
.clos {
  height: 100%;
  .input {
    width: 100%;
  }
  .select {
    width: 100%;
  }
  .datepick {
    width: 100%;
  }
}
.isBind{
  color: red;
  font-size: 10px;
}
</style>
