// 内部通讯录
// sle
<template>
  <div class="body flex-row justify-center">
    <div class="main flex justify-between">
      <div class="leftSide">
        <!-- 搜索框 -->
        <div v-if="false" class="typeSearch flex-centent">
          <a-input-search
            v-model:value="left.searchInput"
            class="searchInput"
            placeholder="搜索"
            @search="leftSearch"
            @change="leftSearchChange"
            :maxlength="20"
          >
          </a-input-search>
          <RedoOutlined @click="refresh" />
        </div>
        <!-- 部门，岗位切换 -->
        <div class="leftType flex-centent">
          <a-radio-group
            v-model:value="left.leftType"
            button-style="solid"
            @change="leftTypeChange"
          >
            <a-radio-button class="radioLeft" value="1"> 组织 </a-radio-button>
            <a-radio-button class="radioRight" value="2">岗位</a-radio-button>
          </a-radio-group>
        </div>
        <!-- 企业名称以及企业人数 -->
        <div class="list">
          <div class="all flex-row">
            <a
              @click="choiseAll()"
              :style="left.choise.id == 'all' ? left.ischoise : left.notChoise"
              class="title flex"
              :title="left.title + '(' + left.employeeSum + ') '"
            >
              <span class="left_title">{{ left.title }}</span
              ><span>
                ({{
                  left.employeeSum > 9999
                    ? Math.floor(left.employeeSum / 1000) / 10 + "万"
                    : left.employeeSum
                }})</span
              >
            </a>
            <div v-if="disabled" class="leftitem">
              <a @click="addLeftItem()" class="plusicon">
                <PlusOutlined></PlusOutlined>
              </a>
            </div>
          </div>
          <!-- 当前部门列表 -->
          <div class="leftList">
            <a-spin :spinning="left.spinning">
              <a-list
                item-layout="horizontal"
                :data-source="left.list"
                :split="false"
              >
                <template #renderItem="{ item }">
                  <div class="leftitem flex-row">
                    <a class="flex-row align-center leftItemTitle">
                      <a-list-item
                        @click="leftItemChange(item)"
                        :style="
                          left.choise.id == item.id
                            ? left.ischoise
                            : left.notChoise
                        "
                        :title="
                          item.departmentName +
                          '(' +
                          item.employeeCount +
                          ') '
                        "
                      >
                        <span class="departmentName">
                          {{ item.departmentName }}
                        </span>
                        <span>
                          ({{
                            item.employeeCount > 9999
                              ? Math.floor(item.employeeCount / 1000) / 10 +
                                "万"
                              : item.employeeCount
                          }})
                        </span>
                      </a-list-item>
                      <a-dropdown
                        :disabled="moreDisabled"
                        :trigger="['click']"
                        v-if="item.id != '' && disabled"
                      >
                        <a
                          :style="
                            left.choise.id == item.id
                              ? left.ischoise
                              : left.notChoise
                          "
                          @click.prevent
                        >
                          <span style="font-size: 16px;">
                            <MoreOutlined />
                          </span>
                          <!-- &nbsp;&nbsp;&nbsp;· · · -->
                        </a>
                        <template #overlay>
                          <a-menu>
                            <a-menu-item
                              key="edit"
                              @click="editLeftItem(item.id)"
                            >
                              <div class="flex">
                                <img src="@/assets/bianji_icon.png" alt="" />
                                <a style="color: black; margin-left: 6px"
                                  >编辑</a
                                >
                              </div>
                            </a-menu-item>
                            <a-menu-item key="del">
                              <a-popconfirm
                                cancelText="取消"
                                okText="确认"
                                :title="'是否确认删除 ' + item.departmentName"
                                @confirm="submitDelLeftItem(item.id)"
                              >
                                <div class="flex">
                                  <img src="@/assets/icon_shanchu.png" alt="" />
                                  <a style="color: black; margin-left: 6px"
                                    >删除</a
                                  >
                                </div>
                              </a-popconfirm>
                            </a-menu-item>
                          </a-menu>
                        </template>
                      </a-dropdown>
                    </a>
                  </div>
                </template>
              </a-list>
            </a-spin>
          </div>
        </div>
      </div>
      <div class="right" :style="{ minHeight: minHeight + 'px' }">
        <div class="head flex-row align-center justify-between">
          <div class="title" :title="left.choise.name">
            {{ left.choise.name }}
          </div>
          <div class="flex right-button">
            <div
              class="flex"
              style="
                margin-right: 20px;
                border-right: 1px solid #dddddd;
                padding-right: 20px;
              "
              v-if="headButtonVisible"
            >
              <img
                class="img"
                src="@/assets/icon_shezhizhuguan.png"
                v-if="disabled"
              />
              <div
                class="button"
                @click="editLeftItem(left.choise.id)"
                v-if="disabled"
              >
                设置主管
              </div>
              <img
                class="img"
                src="@/assets/icon_shezhizhuguan1.png"
                v-if="!disabled"
              />
              <div class="buttonHide" :title="title" v-if="!disabled">
                设置主管
              </div>
            </div>
            <div class="flex button" v-if="invitationDisabled">
              <img class="img" src="@/assets/invite.svg" />
              <img class="img_hover" src="@/assets/invite_blue.svg" />
              <div @click="invitationClick">邀请</div>
            </div>
            <div class="flex buttonHide" v-if="!invitationDisabled">
              <img class="img" src="@/assets/invite_gray.svg" />
              <div :title="title">邀请</div>
            </div>
          </div>
        </div>
        <div class="line_h"></div>
        <div class="buttonGroup">
          <div class="departmentButtons flex-row align-center justify-between">
            <a-input-search
              class="search"
              v-model:value="right.searchInput"
              placeholder="搜索"
              @search="rightSearch"
              @change="rightSearchChange"
              :maxlength="20"
            >
            </a-input-search>
            <span>
              <a-button
                class="button"
                :disabled="!disabled"
                :title="!disabled == true ? title : ''"
                @click="adjustDepartment"
              >
                调整部门
              </a-button>
              <a-button
                class="button"
                :disabled="!disabled"
                :title="!disabled == true ? title : ''"
                @click="addEmployee"
                >添加</a-button
              >
              <a-button
                :class="!disabled ? 'button' : 'delect button'"
                :disabled="!disabled"
                :title="!disabled == true ? title : ''"
                @click="delEmployeeList"
                >删除</a-button
              >
              <a-button class="button" v-show="isShow">导入</a-button>
              <a-button class="button" v-show="isShow">导出</a-button>
            </span>
          </div>
        </div>
        <div class="table">
          <a-spin :spinning="right.spinning">
            <a-table
              :columns="columns"
              :data-source="right.list"
              bordered
              :rowKey="(record) => record.id"
              :pagination="pagination"
              @change="paginationChange"
              :row-selection="{
                selectedRowKeys: right.selectedRows,
                onChange: onSelectChange,
                fixed: true,
              }"
              :scroll="{ x: 1300 }"
            >
              <template #email="{ record }">
                <a-tooltip>
                  <template #title>{{ record.email }}</template>
                  <span class="ellipsis" style="width: 100%">
                    {{ record.email }}
                  </span>
                </a-tooltip>
              </template>
              <template #employeeName="{ record }">
                <a-tooltip>
                  <template #title>{{ record.employeeName }}</template>
                  <span class="ellipsis" style="width: 100%">
                    {{ record.employeeName }}
                  </span>
                </a-tooltip>
              </template>
              <template #isBind="{ record }">
                <a-tooltip>
                  <template #title>{{ record.isBind }}</template>
                  <span class="ecllipsis" style="width: 100%">
                    {{ record.isBind === false ? "否" : "是" }}
                  </span>
                </a-tooltip>
              </template>
              <template style="padding: 10px" #employeePost="{ record }">
                <a-tag
                  v-for="item of record.employeePost"
                  color="blue"
                  :key="item"
                  :title="record.employeePost"
                >
                  {{ item }}</a-tag
                >
              </template>

              <template #operation="{ record }">
                <div class="editable-row-operations">
                  <span class="flex-row justify-around">
                    <a @click="editEmployee(record.id)" v-if="disabled">编辑</a>
                    <a :title="title" class="hide" v-if="!disabled">编辑</a>
                    <a-popconfirm
                      v-if="disabled"
                      cancelText="取消"
                      okText="确认"
                      title="是否确认删除"
                      @confirm="deleteEmployee(record)"
                    >
                      <a>删除</a>
                    </a-popconfirm>
                    <div :title="title" class="hide" v-if="!disabled">删除</div>
                    <a-dropdown :trigger="['click']">
                      <a
                        :style="
                          left.choise.id == 'all'
                            ? left.ischoise
                            : left.notChoise
                        "
                        @click.prevent
                      >
                        &nbsp;&nbsp;&nbsp;· · ·
                      </a>
                      <template #overlay>
                        <a-menu>
                          <a-menu-item>
                            <a-popconfirm
                              v-if="disabled"
                              cancelText="取消"
                              okText="确认"
                              title="是否确认将当前员工离职"
                              @confirm="submitDimission(record)"
                            >
                              <a>离职</a>
                            </a-popconfirm>
                            <div
                              style="color：#b7b7b7"
                              :title="title"
                              class="hide"
                              v-if="!disabled"
                            >
                              离职
                            </div>
                          </a-menu-item>
                        </a-menu>
                      </template>
                    </a-dropdown>
                  </span>
                </div>
              </template>
            </a-table>
          </a-spin>
        </div>
        <!-- 新增/编辑部门弹窗 -->
        <department-edit-view
          ref="department"
          @departmentEditBeforeReload="departmentEditBeforeReload"
        />
        <!-- 新增成员弹窗 -->
        <employee-edit-view
          ref="employee"
          :departmentList="departmentList"
          @employeeEditBeforeReload="employeeEditBeforeReload"
        />
        <!-- 邀请弹窗 -->
        <invitation-create-view ref="invitation" :isEmployee="true" />
        <!-- 批量删除确认弹窗 -->
        <a-modal
          v-model:visible="delListVisible"
          :maskClosable="false"
          title="删除成员"
        >
          <template #footer>
            <a-button key="back" @click="delListCancel()">取消</a-button>
            <a-button
              key="submit"
              type="primary"
              :loading="delListloading"
              @click="submitDelEmployeeList()"
              >确认</a-button
            >
          </template>
          确认删除选中的成员吗？
        </a-modal>
        <!-- 调整部门弹窗 -->
        <a-modal
          :confirmLoading="departmentAdjustView.spinning"
          v-model:visible="departmentAdjustView.visible"
          title="调整部门"
          ok-text="确认"
          :maskClosable="false"
          cancel-text="取消"
          @ok="submitadjustDepartment()"
        >
          <a-spin :spinning="departmentAdjustView.spinning">
            <div class="flex-row align-center align-around">
              <div style="margin-right: 10%">选择部门：</div>
              <a-select
                v-model:value="departmentAdjustView.departmentId"
                style="width: 70%"
                ref="select"
                :allowClear="true"
              >
                <a-select-option
                  v-for="r in departmentList"
                  :key="r.linkKey"
                  :value="r.linkKey"
                  >{{ r.name }}</a-select-option
                >
              </a-select>
            </div>
          </a-spin>
        </a-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import { minheight } from '../../components/sameVariable'
import { isEmpty, addressBookJurisdiction, inviteJurisdiction, tenantIsComplete } from '@/assets/common.js'
import EmployeeEditView from './EmployeeEditView.vue'
import DepartmentEditView from './DepartmentEditView.vue'
import InvitationCreateView from '@/views/AddressBook/components/InvitationCreateView.vue'
import { getTableColumnData } from '@/api/tableAbout/tableAPI'
import { RedoOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    'employee-edit-view': EmployeeEditView,
    'department-edit-view': DepartmentEditView,
    'invitation-create-view': InvitationCreateView,
    MoreOutlined,
    RedoOutlined,
    PlusOutlined,
  },
  data () {
    return {
      isShow: false,
      title: '暂无权限',
      disabled: false,
      disabledTenant: false,
      invitationDisabled: false, // 邀请
      moreDisabled: false,
      fixed: 'right',
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      }, // 分页部分
      minHeight: '400',
      left: {
        title: '',
        leftType: '1', // 当前选中的类型
        searchInput: '', // 搜索栏内容
        isSearch: false, // 是否在搜索中
        spinning: false, // 是否加载
        list: [], // 左侧列表
        employeeSum: '', // 人员总数
        choise: {
          id: '', // 选中的id
          name: '', // 选中的名称
        }, // 当前选中
        ischoise: 'color:#3399ff', // 选中时颜色
        notChoise: 'color:black', // 未选中时颜色
      }, // 左侧
      right: {
        spinning: false, // 是否加载
        searchInput: '', // 搜索栏内容
        isSearch: false, // 是否在搜索中
        list: [], // 右侧列表
        selectedRows: [],
      }, // 右侧
      columns: [],
      headButtonVisible: false, // 编辑按钮是否显示
      departmentAdjustView: {
        visible: false,
        spinning: false,
        departmentId: null, // 选择的部门Id
      }, // 部门调整页面
      delListVisible: false, // 批量删除确认弹框是否显示
      delListloading: false, // 批量删除按钮是否加载
      departmentList: [], // 部门下拉
    }
  },
  // 页面加载事件
  created () {
    this.minHeight = minheight
    this.getTableColumnData()
    this.loadLeftList()
    this.left.choise.id = 'all'
    this.getAllTableList()
    this.getJurisdiction()
    const tenantList = JSON.parse(localStorage.getItem('tenantList'))
    this.left.title = tenantList.enterpriseName
    this.left.choise.name = this.left.title
  },
  // 方法
  methods: {
    // 刷新
    refresh () {
      this.loadLeftList()
    },
    // 获取邀请权限
    getJurisdiction () {
      this.disabled = addressBookJurisdiction() // 通讯录权限
      this.disabledTenant = tenantIsComplete() // 企业是否创建完成
      this.invitationDisabled = inviteJurisdiction() // 是否有邀请权限

      if (this.disabledTenant === false) {
        this.disabled = false
        this.invitationDisabled = false
      }
      if (this.disabled === true) {
        this.moreDisabled = null
      }
    },
    // getJurisdiction () {
    //   this.disabled = addressBookJurisdiction()
    //   if (addressBookJurisdiction() === true) {
    //     this.moreDisabled = null
    //   }
    //   this.invitationDisabled = inviteJurisdiction()
    // },
    quit (e) {
      // console.log(e)
    },
    // 获取当前table的列信息
    async getTableColumnData () {
      const data = await getTableColumnData('InteriorLinkMan', 'addressBook', true)
        .catch(err => {
          this.left.spinning = false
          // this.$message.error('获取列结构失败')
          console.log(err)
        })
      const list = data
      const arr = []
      list.map((item, index) => {
        arr.push(Object.assign({}, item, { width: 150 }))
      })
      this.columns = arr
    },
    // #region 左侧列表事件
    // 左侧搜索栏变化事件
    leftSearchChange () {
      if (isEmpty(this.left.searchInput) && this.left.isSearch) {
        this.left.isSearch = false
        this.loadLeftList()
      }
    },
    // 左侧搜索
    leftSearch (e) {
      if (isEmpty(this.left.searchInput)) {
        return
      }
      this.left.isSearch = true
      this.loadLeftList()
    },

    // 左侧类型变更跳转至部门页面
    leftTypeChange () {
      this.$router.push({ path: '/AddressBook/PostDuty' })
    },

    // 左侧企业名称 点击事件
    choiseAll () {
      // 当前选中项更新为企业名称，选中项id更新为all，当前所在页更新为1，不显示右侧按钮，选中项清空，页码总数为企业人数，获取成员列表
      this.right.selectedRows = []
      this.pagination.current = 1
      this.right.searchInput = ''
      this.right.isSearch = false
      this.left.choise.name = this.left.title
      this.left.choise.id = 'all'
      this.headButtonVisible = false
      this.pagination.total = this.left.employeeSum
      this.getAllTableList()
    },
    // 左侧部门列表，选项变更事件
    leftItemChange (item) {
      this.right.selectedRows = []
      this.pagination.current = 1
      this.right.searchInput = ''
      this.right.isSearch = false
      this.left.choise.name = item.departmentName
      this.left.choise.id = item.id
      this.headButtonVisible = !isEmpty(item.id)
      this.pagination.total = item.employeeCount
      this.getListByLeftItem()
    },
    // 获取部门列表数据
    loadLeftList () {
      this.left.spinning = true
      api
        .get('/api/app/department/department-list-group', {
          params: {
            SearchInput: this.left.searchInput,
          },
        })
        .then(({ data }) => {
          this.left.spinning = false
          this.left.list = data
          for (let i = 0; i < data.length; i++) {
            if (!data[i].departmentName === '未分配') {
              this.choiseAll()
            }
          }
          // this.left.list.forEach(element => {
          //   console.log(element)
          //   const index = element.departmentName.indexOf('未分配')
          //   console.log(index)
          //   if (index === -1) {
          //     this.choiseAll()
          //   }
          // })
          // 若是正在搜索，则不走以下方法
          if (this.left.isSearch) {
            return
          }
          const selectList = []
          this.left.employeeSum = 0
          data.forEach(element => {
            this.left.employeeSum += element.employeeCount
            if (!isEmpty(element.id)) {
              const ojb = {
                linkKey: element.id,
                name: element.departmentName,
                code: element.id,
              }
              selectList.push(ojb)
            }
          })
          this.departmentList = selectList
          if (this.left.choise.id === 'all') {
            this.first = false
            this.pagination.total = this.left.employeeSum
          }
        })
        .catch(err => {
          this.left.spinning = false
          // this.$message.error('获取部门列表失败')
          console.log(err)
        })
    },
    // #endregion

    // #region 部门管理部分
    // 添加部门按钮，呼出添加部门窗口
    addLeftItem () {
      this.$refs.department.addDepartment()
    },
    // 设置主管、编辑按钮，呼出编辑部门窗口
    editLeftItem (id) {
      this.$refs.department.editDepartment(id)
    },
    // 新增，编辑后刷新
    departmentEditBeforeReload (name, id) {
      if (this.left.choise.id === id) {
        this.left.choise.name = name
      }
      this.loadLeftList()
    },
    // 确认删除部门操作
    submitDelLeftItem (id) {
      for (let i = 0; i < this.left.list.length; i++) {
        const element = this.left.list[i]
        if (element.id === id) {
          if (element.employeeCount !== 0) {
            this.$message.error('请先清空当前部门下的成员')
            return
          }
          if (element.managerCount !== 0) {
            this.$message.error('请先撤销当前部门的负责人')
            return
          }
          break
        }
      }
      this.left.spinning = true
      api
        .delete('/api/app/department/department', {
          params: {
            Id: id,
          },
        })
        .then(({ data }) => {
          this.$message.success('操作成功')
          this.loadLeftList()
          if (this.left.choise.id === id) {
            this.choiseAll()
          }
        })
        .catch(err => {
          this.left.spinning = false
          // this.$message.error('操作失败')
          console.log(err)
        })
    },
    // #endregion

    // #region 右侧列表
    // 成员列表 页码变更
    paginationChange (e) {
      this.pagination.current = e.current
      this.getRightData()
    },
    // 获取右侧列表数据
    getRightData () {
      if (this.left.choise.id === 'all') {
        this.getAllTableList()
      } else {
        this.getListByLeftItem()
      }
    },
    // 成员列表选择事件
    onSelectChange (selectedRows) {
      this.right.selectedRows = selectedRows
    },

    // 获取所有人员数据
    getAllTableList () {
      this.right.spinning = true
      api
        .get('/api/app/employee/paging-employee-list-pC', {
          params: {
            SearchInput: this.right.searchInput,
            PageSize: this.pagination.pageSize,
            Pagination: this.pagination.current,
          },
        })
        .then(({ data }) => {
          this.right.spinning = false
          this.right.list = data.list
          if (this.right.searchInput === '') {
            const num = isEmpty(this.left.employeeSum) ? 0 : this.left.employeeSum
            this.pagination.total = num
          } else {
            this.pagination.total = data.pageCount
          }
        })
        .catch(err => {
          this.right.spinning = false
          // this.$message.error('获取成员数据失败')
          console.log(err)
        })
    },
    // 根据指定部门显示其下成员数据
    getListByLeftItem () {
      this.right.spinning = true
      api
        .get('/api/app/employee/employee-list-by-depment-id-pC', {
          params: {
            input: this.left.choise.id,
            SearchInput: this.right.searchInput,
            PageSize: this.pagination.pageSize,
            Pagination: this.pagination.current,
          },
        })
        .then(({ data }) => {
          this.right.spinning = false
          this.right.list = data.list
          // if (this.right.searchInput === '') {
          //   this.pagination.total = this.left.employeeSumF
          // } else {
          this.pagination.total = data.pageCount
          // }
        })
        .catch(err => {
          this.right.spinning = false
          // this.$message.error('获取成员数据失败')
          console.log(err)
        })
    },
    // #endregion

    // #region table 上方按钮
    // 右侧搜索栏变化事件
    rightSearchChange () {
      if (isEmpty(this.right.searchInput) && this.right.isSearch) {
        this.right.isSearch = false
        this.pagination.current = 1
        this.getRightData()
      }
    },
    // 右侧搜索
    rightSearch (e) {
      if (isEmpty(this.right.searchInput)) {
        return
      }
      this.pagination.current = 1
      this.right.isSearch = true
      this.getRightData()
    },

    // 添加成员按钮，呼出添加页面
    addEmployee () {
      const departmentId = this.left.choise.id
      this.$refs.employee.addEmployee(departmentId)
      if (departmentId === '') {
        this.$refs.employee.addEmployee()
      }
    },
    // 批量删除按钮，呼出确认页面
    delEmployeeList () {
      if (this.right.selectedRows.length === 0) {
        this.$message.error('请选择需要删除的成员')
        return
      }
      this.delListVisible = true
    },
    // 确认批量删除成员操作
    submitDelEmployeeList () {
      const sublistIds = []
      this.right.selectedRows.forEach(element => {
        sublistIds.push(element)
      })
      this.right.spinning = true
      this.delListloading = true
      api
        .post('/api/app/employee/batch-delete-employee-list', {
          sublistIds: sublistIds,
          // 删除方式 被管理员移除
          Type: 0,
        })
        .then(({ data }) => {
          this.$message.success('操作成功')
          this.delListVisible = false
          this.delListloading = false
          this.loadLeftList()
          this.getRightData()
        })
        .catch(err => {
          this.delListloading = false
          this.right.spinning = false
          // this.$message.error('操作失败')
          console.log(err)
        })
    },
    // 取消批量删除
    delListCancel () {
      this.delListVisible = false
      this.delListloading = false
      this.right.spinning = false
    },

    // 调整部门成员按钮，呼出调整页面
    adjustDepartment () {
      if (this.right.selectedRows.length === 0) {
        this.$message.error('请选择需要调整部门的成员')
        return
      }
      this.departmentAdjustView.visible = true
    },
    // 确认调整部门成员操作
    submitadjustDepartment () {
      const sublistIds = []
      this.right.selectedRows.forEach(element => {
        sublistIds.push(element)
      })
      this.departmentAdjustView.spinning = true
      api
        .post('/api/app/employee/adjust-department', {
          sublistIds: sublistIds,
          mainId: this.departmentAdjustView.departmentId,
        })
        .then(({ data }) => {
          this.$message.success('操作成功')
          this.departmentAdjustView.spinning = false
          this.departmentAdjustView.visible = false
          this.loadLeftList()
          this.getRightData()
        })
        .catch(err => {
          this.departmentAdjustView.spinning = false
          // this.$message.error('操作失败')
          console.log(err)
        })
    },

    // 编辑后刷新
    employeeEditBeforeReload () {
      this.left.pagination = 1
      this.loadLeftList()
      this.getRightData()
    },
    // 邀请按钮
    invitationClick () {
      this.$refs.invitation.invitationViewShow()
    },
    // #endregion

    // #region 员工按钮
    // 编辑成员按钮
    editEmployee (id) {
      this.$refs.employee.editEmployee(id)
    },
    // 删除成员（单个）操作
    deleteEmployee (e) {
      this.right.spinning = true
      api
        .delete('/api/app/employee/employee', {
          params: {
            Id: e.id,
            // 删除方式 被管理员移除
            Type: 0,
          },
        })
        .then(({ data }) => {
          this.$message.success('操作成功')
          this.loadLeftList()
          this.getRightData()
        })
        .catch(err => {
          this.right.spinning = false
          // this.$message.error('操作失败')
          console.log(err)
        })
    },
    // 离职
    submitDimission (e) {
      this.right.spinning = true
      api
        .post('/api/app/employee/employee-dimission', {
          employeeId: e.id,
          employeeState: 4,
        })
        .then(({ data }) => {
          this.$message.success('操作成功')
          this.loadLeftList()
          this.getRightData()
        })
        .catch(err => {
          this.right.spinning = false
          // this.$message.error('操作失败')
          console.log(err)
        })
    },
    // #endregion
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";
.body {
  width: 100%;
  .main {
    width: 82%;
    .leftSide {
      border-radius: 2px;
      width: 14%;
      height: calc(90% - 135px);
      background: white;
      position: fixed;
      z-index: 10;
      .choise {
        color: #3399ff;
      }
      .typeSearch {
        margin-top: 20px;
        width: 100%;
        height: 36px;
        .searchInput {
          width: 75%;
          background: #fff;
          margin-right: 10px;
        }
      }
      .leftType {
        margin-top: 20px;
        width: 100%;
        height: 36px;
        .radioLeft {
          padding: 0 25px;
          border-radius: 2px 0px 0px 2px;
        }
        .radioRight {
          padding: 0 25px;
          border-radius: 0px 2px 2px 0px;
        }
      }
      .list {
        max-height: 72%;
        overflow: auto;
        overflow-x: hidden;
      }
      .all {
        margin-top: 10px;
        padding-left: 10%;
        width: 100%;
        height: 36px;
        font-size: 18px;
        letter-spacing: 0px;
        a: {
          color: black;
        }
        .leftitem {
          margin-left: 10%;
          // width: 100%;
          .plusicon {
            font-size: 18px;
            position: absolute;
            left: 86%;
            color: grey;
          }
          .plusicon::before {
            content: "添加部门";
            position: absolute;
            width: 100px;
            background-color: #062b45;
            color: white;
            text-align: center;
            padding: 10px;
            line-height: 1.2;
            border-radius: 6px;
            z-index: 1;
            opacity: 0;
            transition: opacity 0.6s;
            margin-left: 100%;
            bottom: -10px;
            font-size: 0.75em;
            visibility: hidden;
            word-break: break-all;
          }
          .plusicon:hover:before {
            opacity: 1;
            visibility: visible;
          }
        }
      }
      .left_title {
        max-width: 85%;
        overflow: hidden;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .title {
        width: 85%;
        // overflow: hidden;
        white-space: nowrap;
        // text-overflow: ellipsis;
      }
      .departmentName {
        max-width: 85%;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .department {
        display: inline-block;
      }
      .leftList {
        width: 100%;
        margin-top: 5px;
        .leftitem {
          margin-left: 10%;
          width: 90%;
        }
        .leftItemTitle {
          width: 74%;
        }
        .ant-list-item {
          width: 95%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          justify-content: flex-start;
        }
        .ant-dropdown-trigger {
          position: absolute;
          left: 87%;
        }
      }
    }
    .right {
      margin-left: 19%;
      width: 81%;
      background-color: #fff;
      border-radius: 5px;
      .head {
        letter-spacing: 1px;
        background: #fff;
        padding: 0px 20px;
        border-radius: 5px;
        height: 60px;
        font-size: 18px;
        .right-button {
          position: relative;
          // top: 20px;
        }
        .title {
          max-width: 300px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .button {
          font-size: 16px;
          cursor: pointer;
          .img{
            display:block;
            width: 22px;
            height: 22px;
            margin-right: 10px;
          }
          .img_hover{
            display:none;
            width: 22px;
            height: 22px;
            margin-right: 10px;
          }
        }
        .button:hover{
          font-size: 16px;
          color: #3479db;
          cursor: pointer;
          .img{
            display:none;
          }
          .img_hover{
            display:block;
          }
        }
        .buttonHide {
          font-size: 16px;
          color: #b7b7b7;
          cursor: not-allowed;
        }
        .img {
          width: 22px;
          height: 22px;
          margin-right: 10px;
        }
      }
      .buttonGroup {
        height: 80px;
        line-height: 80px;
        .button {
          margin-right: 20px;
        }
        .buttons {
          height: 100%;
        }
        .search {
          flex-direction: row-reverse;
          width: 200px;
          margin-left: 24px;
        }
        .delect {
          border-color: #fc497a;
          color: #fc497a;
        }
        .invite {
          color: black;
          margin-left: 40px;
        }
      }
      .table {
        padding: 0px 24px;
        margin: 0;
      }
      .hide {
        color: #b7b7b7;
        cursor: not-allowed;
      }
    }
  }
}

.list::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 3px;
}
.list::-webkit-scrollbar-thumb {
  background: #d1d1d1;
  border-radius: 10px;
}
.list::-webkit-scrollbar-thumb:hover {
  background: #d1d1d1;
}

::v-deep(.ant-table-bordered .ant-table-thead) > tr > th {
  text-align: center;
  border-right: none;
  padding: 12px 10px;
}
::v-deep(.ant-table-bordered .ant-table-tbody) > tr > td {
  text-align: center;
  padding: 12px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-right: none;
}
::v-deep(.ant-table-bordered .ant-table-fixed-right) table {
  border-right: 1px solid #f0f0f0;
}
::v-deep(.ant-dropdown-menu-item) div {
  color: #b7b7b7;
  cursor: not-allowed;
}
::v-deep(.ant-input-affix-wrapper-lg){
  flex-direction: row-reverse;
}
::v-deep(.ant-input-suffix){
  margin-left: 0px;
  padding-right:6px;
}
</style>
