// 部门新增，编辑弹窗
// sle
<template>
  <div id="DepartmentEditView">
    <a-modal
      :confirmLoading="spinning"
      v-model:visible="visible"
      :maskClosable="false"
      :title="title"
      ok-text="保存"
      cancel-text="取消"
      @ok="departmentViewSubit"
      @cancel="closeDepartmentView"
    >
      <a-spin :spinning="spinning">
        <a-form
          :model="obj"
          ref="formRef"
          :rules="rules"
          autocomplete="off"
          :labelCol="{ span: 4 }"
          :wrapperCol="{ span: 14 }"
        >
          <a-form-item label="部门名称" required name="departmentName">
            <a-input
              v-model:value="obj.departmentName"
              required
              :maxlength="15"
            />
          </a-form-item>
          <a-form-item label="部门主管">
            <a-select
              mode="multiple"
              v-model:value="selectRows"
              style="width: 100%"
              placeholder="选择部门主管"
            >
              <a-select-option
                v-for="r in selectList"
                :key="r.linkKey"
                :value="r.linkKey"
                >{{ r.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'

export default defineComponent({
  emits: ['departmentEditBeforeReload'],
  data () {
    return {
      selectList: [], // 选择部门主管时的下拉列表
      selectRows: [], // 选中行
      spinning: false, // 是否加载
      visible: false, // 弹窗是否显示
      add: false, // 是否新增操作
      title: '', // 弹窗标题
      obj: {
        id: null,
        departmentName: '', // 部门名称
        contactList: [], // 主管
      }, //
      rules: {
        departmentName: [
          {
            required: true,
            message: '请输入部门名称',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 30,
            message: '长度请控制在30以内',
            trigger: 'blur',
          },
        ],
      }, // 验证规则
    }
  },
  methods: {
    addDepartment () {
      this.visible = true
      this.title = '添加部门'
      this.add = true
      this.getEmployeeSelectList()
    },
    // 设置主管、编辑按钮，呼出编辑部门窗口
    editDepartment (id) {
      this.getDepartmentData(id)
    },
    // 获取选中部门的详情数据
    getDepartmentData (id) {
      this.spinning = true
      api
        .get('/api/app/department/department', {
          params: {
            Id: id,
          },
        })
        .then(({ data }) => {
          this.spinning = false
          this.obj = data
          const selectRows = []
          data.contactList.forEach(e => {
            selectRows.push(e.employeeId)
          })
          this.selectRows = selectRows
          this.visible = true
          this.title = '编辑'
          this.add = false
          this.getEmployeeSelectList(id)
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取部门信息失败')
          console.log(err)
        })
    },
    // 获取员工下拉数据
    getEmployeeSelectList (id) {
      api
        .get('/api/app/employee/select-list-by-depment-id')
        .then(({ data }) => {
          this.selectList = data
        })
        .catch(err => {
          // this.$message.error('获取成员数据失败')
          console.log(err)
        })
    },
    // 取消，关闭部门编辑窗口
    closeDepartmentView () {
      this.visible = false
      this.title = ''
      this.add = false
      this.$refs.formRef.resetFields()
      this.obj = {}
      this.selectRows = []
    },
    // 确认设置主管、编辑操作
    departmentViewSubit () {
      this.$refs.formRef
        .validate()
        .then(() => {
          this.spinning = true
          const contactList = []
          for (let i = 0; i < this.selectRows.length; i++) {
            const employeeId = this.selectRows[i]
            const employees = this.selectList.find(function (item) {
              return item.linkKey === employeeId
            })
            const obj = {
              employeeId: employeeId,
              employeesName: employees.name,
            }
            contactList.push(obj)
          }
          const name = this.obj.departmentName
          const id = this.obj.id
          const obj = {
            departmentName: name,
            contactList: contactList,
          }
          if (!this.add) {
            obj.id = id
            obj.concurrencyStamp = this.obj.concurrencyStamp
          }
          api
            .post('/api/app/department/or-update-department', obj)
            .then(({ data }) => {
              this.$message.success('操作成功')
              this.closeDepartmentView()
              this.$emit('departmentEditBeforeReload', name, id)
              this.spinning = false
            })
            .catch(err => {
              this.spinning = false
              // this.$message.error('操作失败')
              console.log(err)
            })
        })
        .catch(error => {
          console.log('error', error)
        })
    },
  },
})
</script>
